import { UserControllerApi } from '@rallycry/api-suite-typescript/dist/apis/UserControllerApi'
import { UserMetaResource } from '@rallycry/api-suite-typescript/dist/models/UserMetaResource'
import { EntityOptions, useReadEntity } from '../useEntity'
import { useUserAccount } from './useUserAccount'
import { useController } from '@/core/hooks/useSWRApi'

export const useUserMeta = (options?: EntityOptions) => {
  const { account } = useUserAccount()
  const { ctrl } = useController(UserControllerApi)

  const entity = useReadEntity({
    key: 'useUserMeta',
    idOrKey: account?.id || -1,
    persist: true,
    ...options,
    read: req =>
      Number(req.idOrKey) > 0
        ? ctrl({ expectedStatuses: [404], fallbackResponse: {} })
            .readUserMeta(req)
            // split off inboxSequenceValue from the rest of the meta to prevent excessive re-renders
            .then(({ inboxSequenceValue, ...rest }) => rest as UserMetaResource)
        : Promise.resolve({} as UserMetaResource)
  })

  return { ...entity, meta: entity.read.data }
}
