import {
  BaseAPI,
  Configuration,
  HTTPHeaders,
  RequestContext,
  ResponseContext
} from '@rallycry/api-suite-typescript/dist/runtime'
import {
  BaseAPI as SocialBaseAPI,
  Configuration as SocialConfiguration
} from '@rallycry/social-api-typescript/dist/runtime'
import {
  BaseAPI as UniversalBaseAPI,
  Configuration as UniversalConfiguration
} from '@rallycry/universal-api-typescript/dist/runtime'
import { isUndefined, some } from 'lodash-es'
import { useConfig } from '../../entity/site/useConfig'
import { RootRoute } from '../route-keys'
import { ErrorCode } from '../error-code'
import { useRcTranslation } from './useRcTranslation'
import { useNavigation } from './useNavigation'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'
import { useImpersonation } from '@/components/providers/site/ImpersonationProvider'
import { usePage } from '@/components/providers/site/PageProvider'

export const useSWRApi = (basePath: string, skipRaosa?: boolean) => {
  const { open, accepted, resetAccepted } = usePage()
  const { user } = useFirebase()
  const { impersonation, organization, inheritPermissions, isProfile } =
    useImpersonation()
  const { replaceTo } = useNavigation()
  const { i18n } = useRcTranslation()
  const language = i18n.language

  const getCfg: any = (options: any) => {
    const metaObject = options?.metas || {}
    const metaKeys = Object.keys(metaObject)

    const impersonationId = options?.skipImpersonation ? 0 : impersonation?.id
    const runAs = impersonationId?.toString() || user?.uid || 0
    let cacheVary = `uid-${runAs}`

    metaKeys.forEach(key => {
      const metaVal = metaObject[key]
      if (isUndefined(metaVal)) return
      cacheVary += ` ${key}-${metaVal}`
    })

    const headers: HTTPHeaders = {
      ['Cache-Vary']: cacheVary,
      ['accept-language']: language
    }

    if (accepted?.()) {
      headers['Bypass-Issue'] = accepted()
    }

    if (options?.skipCache) {
      headers['Cache-Control'] = 'no-cache'
    }

    const cfg = new Configuration({
      basePath,
      headers,
      middleware: [
        {
          pre: async (context: RequestContext) => {
            const token = await user?.getIdToken()
            const hasToken = !!token
            const authorization = options?.rcea
              ? `rcea ${options.rcea}`
              : hasToken
                ? `Bearer ${token}`
                : ''

            const headers: [string, string][] = []
            if (options?.ticket) {
              headers.push([`Ticket`, options.ticket])
            }

            if (authorization && !options?.skipAuth) {
              headers.push([`authorization`, authorization])
            }

            if (organization && !skipRaosa) {
              headers.push([`raosa`, organization.toString()])
            }

            if (impersonationId && hasToken) {
              headers.push([`impersonate-user`, impersonationId.toString()])
              headers.push([
                `impersonate-inherit`,
                (isProfile || inheritPermissions || '').toString()
              ])
            }

            context.init.headers = {
              ...context.init.headers,
              ...Object.fromEntries(headers)
            }
          }
        },
        {
          post: async (context: ResponseContext) => {
            if (options?.expectedStatuses?.includes(context.response.status)) {
              return isUndefined(options.fallbackResponse)
                ? context.response
                : new Response(
                    new Blob([JSON.stringify(options.fallbackResponse)]),
                    {
                      status: 200
                    }
                  )
            }

            if (context.response.ok) {
              resetAccepted?.()
            } else {
              try {
                const issues: any[] = (
                  await context.response?.clone?.()?.json()
                )?.issues
                issues
                  ?.map((it: any) => `${it.message} (${it.code})`)
                  .join(', ')

                if (
                  issues.some((it: any) => it.code === ErrorCode.MfaRequired)
                ) {
                  replaceTo({ root: RootRoute.MfaLink })
                }

                // show a snackbar with error for failed updates
                if (
                  context.init.method !== 'GET' &&
                  !options?.skipErrorDisplay
                ) {
                  open?.(
                    undefined,
                    some(issues)
                      ? issues
                          ?.map((it: any) => `${it.message} (${it.code})`)
                          .join(', ')
                      : `Unable to process request:  (${context.response.status}) ${context.url}`,
                    5000
                  )
                }
              } catch {}
            }

            return context.response
          }
        }
      ]
    })

    return cfg
  }

  return { getCfg }
}

export const useController = <T extends BaseAPI>(
  ctor: new (cfg: Configuration) => T
) => {
  const config = useConfig()
  const { getCfg } = useSWRApi(config.api)

  const getApi = <T extends BaseAPI>(
    ctor: new (cfg: Configuration) => T,
    options?: ApiOptions
  ) => {
    const cfg = getCfg(options)
    return new ctor(cfg)
  }

  return {
    ctrl: (options?: ApiOptions) => getApi(ctor, options)
  }
}

export const useSocialController = <T extends SocialBaseAPI>(
  ctor: new (cfg: SocialConfiguration) => T
) => {
  const config = useConfig()
  const { getCfg } = useSWRApi(config.social, true)

  const getApi = <T extends SocialBaseAPI>(
    ctor: new (cfg: SocialConfiguration) => T,
    options?: ApiOptions
  ) => {
    const cfg = getCfg(options)
    return new ctor(cfg)
  }

  return {
    ctrl: (options?: ApiOptions) => getApi(ctor, options)
  }
}
export const useUniversalController = <T extends UniversalBaseAPI>(
  ctor: new (cfg: UniversalConfiguration) => T
) => {
  const config = useConfig()
  const { getCfg } = useSWRApi(config.universal, true)

  const getApi = <T extends UniversalBaseAPI>(
    ctor: new (cfg: UniversalConfiguration) => T,
    options?: ApiOptions
  ) => {
    const cfg = getCfg(options)
    return new ctor(cfg)
  }

  return {
    ctrl: (options?: ApiOptions) => getApi(ctor, options)
  }
}

interface ApiOptions {
  rcea?: string
  ticket?: string
  metas?: Record<string, number>
  skipCache?: boolean
  skipAuth?: boolean
  skipErrorDisplay?: boolean
  skipImpersonation?: boolean
  expectedStatuses?: number[]
  fallbackResponse?: any
}
