import { OrganizationControllerApi } from '@rallycry/api-suite-typescript/dist/apis/OrganizationControllerApi'
import { useConfig } from '../site/useConfig'
import { EntityOptions, useReadEntity } from '../useEntity'
import { useController } from '@/core/hooks/useSWRApi'

export const useOrganizationMeta = (options?: EntityOptions) => {
  const config = useConfig()
  const { ctrl } = useController(OrganizationControllerApi)

  const entity = useReadEntity({
    key: 'useOrganizationMeta',
    idOrKey: config.orgKey,
    persist: true,
    ...options,
    read: req =>
      ctrl({
        expectedStatuses: [401],
        fallbackResponse: {}
      }).readOrganizationMeta(req)
  })

  return {
    ...entity,
    meta: entity.read.data,
    organizationId: entity.read.data?.id
  }
}
