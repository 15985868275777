import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import Tooltip, { TooltipProps } from '@mui/material/Tooltip'
import Color from 'color'
import React, { useState } from 'react'
import { isFunction } from 'lodash-es'
import { RcIconProps } from '@/components/atoms/RcIcon'

export interface RcIconButtonProps extends IconButtonProps {
  icon: IconProp
  badge?: boolean
  IconProps?: Omit<RcIconProps, 'icon'>
  TooltipProps?: Omit<TooltipProps, 'children'>
  alignRight?: boolean
}

const defaultTooltipProps = {
  enterDelay: 0,
  placement: 'top'
} as TooltipProps

export const RcIconButton = ({
  icon,
  badge,
  TooltipProps,
  IconProps,
  onClick,
  alignRight,
  size = 'small',
  ref,
  ...rest
}: RcIconButtonProps) => {
  const [disabled, setDisabled] = useState(false)

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setDisabled(true)
    try {
      await onClick?.(event)
    } finally {
      setDisabled(false)
    }
  }

  const button = (
    // index.js:1 MUI: You are providing a disabled `button` child to the Tooltip component.
    // A disabled element does not fire events.
    // Tooltip needs to listen to the child element's events to display the title.
    //
    // Add a simple wrapper element, such as a `span`.
    <Box
      justifyContent={alignRight ? 'flex-end' : `inherit`}
      display='inline-block'
    >
      <IconButton
        size={size}
        {...rest}
        sx={theme => ({
          ...((isFunction(rest.sx) ? rest.sx(theme) : rest.sx) as any),
          aspectRatio: '1.0',
          backgroundColor: rest.disableRipple
            ? 'none'
            : Color(theme.palette.background.default).alpha(0.2).string()
        })}
        ref={ref}
        disabled={rest.disabled || disabled}
        onClick={handleClick}
      >
        <Badge
          sx={{
            width: 20,
            height: 20,
            alignItems: 'center',
            justifyContent: 'center'
          }}
          invisible={!badge}
          variant='dot'
          color='error'
          overlap='circular'
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <FontAwesomeIcon
            icon={icon}
            size={IconProps?.size}
            id={rest.id}
            {...IconProps}
          />
        </Badge>
      </IconButton>
    </Box>
  )

  return TooltipProps ? (
    <Tooltip {...defaultTooltipProps} {...TooltipProps}>
      {button}
    </Tooltip>
  ) : (
    button
  )
}
