import { UserResource } from '@rallycry/api-suite-typescript/dist/models/UserResource'

export const getCacheKey = <TReq>({
  req,
  expand,
  meta,
  uid,
  impersonation,
  optionKey,
  lastUpdated
}: {
  req: TReq
  expand?: string
  meta?: Record<string, number>
  uid?: string
  impersonation?: UserResource | null | undefined
  optionKey: string
  lastUpdated?: string
}) => {
  const impersonationValue = impersonation?.id

  return [
    req,
    expand,
    meta,
    uid,
    impersonationValue,
    optionKey || '',
    lastUpdated || ''
  ] as const
}
