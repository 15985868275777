export enum ExpansionType {
  //Achievement
  Achievement = 'achievement',
  // misc
  Game = 'game',
  Platform = 'platform',
  Organization = 'organization',
  User = 'user',
  Activity = 'activity',
  ReferenceActivity = 'referenceActivity',
  Recipient = 'recipient',
  // activity
  ActivityPool = 'activityPool',
  Participant = 'participant',
  Rally = 'rally',
  RallyAudience = 'rallyAudience',
  RallyGame = 'rallyGame',
  RallyActivity = 'rallyActivity',
  // bracket
  BracketAssignment = 'bracketAssignment',
  // community
  Community = 'community',
  Communities = 'communities',
  CommunityParent = 'communityParent',
  CommunityProfile = 'communityProfile',
  CommunitySection = 'communitySection',
  CommunitySettings = 'communitySettings',
  CommunityChannel = 'communityChannel',
  CommunityMemberMember = 'communityMemberMember',
  CommunityMemberPreferences = 'communityMemberPreferences',
  CommunityMemberTopGames = 'communityMemberTopGames',
  CommunityAdditionalOrganizations = 'additionalOrganization',
  // competition
  Competition = 'competition',
  CompetitionBracket = 'competitionBracket',
  CompetitionBracketSettings = 'competitionBracketSettings',
  CompetitionCommunity = 'competitionCommunity',
  CompetitionEntry = 'competitionEntry',
  CompetitionEvent = 'competitionEvent',
  CompetitionLeader = 'competitionLeader',
  CompetitionOfficer = 'competitionOfficer',
  CompetitionParticipant = 'competitionParticipant',
  CompetitionProfile = 'competitionProfile',
  CompetitionSection = 'competitionSection',
  CompetitionSettings = 'competitionSettings',
  CompetitionChannel = 'competitionChannel',
  CompetitionRepresenting = 'competitionRepresenting',
  SupportingCause = 'supportingCause',
  SubmissionScore = 'competitionSubmissionScore',
  SubmissionScoreBonus = 'competitionSubmissionScoreBonus',

  // domain
  Domain = 'domain',
  // entry-request
  EntryRequestAgent = 'entryRequestAgent',
  // league
  League = 'league',
  // organization
  OrganizationProfile = 'organizationProfile',
  OrganizationSettings = 'organizationSettings',
  // payment
  Offer = 'offer',
  Order = 'order',
  Product = 'product',
  DiscordGuild = 'discordGuild',

  // Form response
  CuratedForm = 'curatedForm',
  CompetitionFormFilter = 'competitionFormFilter',

  // misc
  AccessGroup = 'accessGroup'
}

export const Expansions = {
  //Achievement
  achievement: { property: 'achievement', expander: 'achievement' },
  // misc
  game: { property: 'game', expander: 'game' },
  platform: { property: 'platform', expander: 'platform' },
  organization: { property: 'organization', expander: 'organization' },
  user: { property: 'user', expander: 'user' },
  activity: { property: 'activity', expander: 'activity' },
  referenceActivity: { property: 'referenceActivity', expander: 'activity' },
  recipient: { property: 'recipient', expander: 'user' },

  // activity
  activityPool: { property: 'activityPool', expander: 'activityPool' },
  participant: { property: 'participant', expander: 'user' },
  rally: { property: 'rally', expander: 'rally' },
  rallyAudience: { property: 'rallyAudience', expander: 'rallyAudience' },
  rallyGame: { property: 'rallyGame', expander: 'rallyGame' },
  rallyActivity: { property: 'activity', expander: 'activityParticipant' },

  // bracket
  bracketAssignment: { property: 'entry', expander: 'bracketAssignment' },

  // community
  community: { property: 'community', expander: 'community' },
  communities: { property: 'communities', expander: 'communities' },
  communityParent: { property: 'parent', expander: 'community' },
  communityProfile: { property: 'profile', expander: 'communityProfile' },
  communitySection: { property: 'section', expander: 'communitySection' },
  communitySettings: { property: 'settings', expander: 'communitySettings' },
  communityChannel: { property: 'channel', expander: 'communityChannel' },
  communityMemberMember: { property: 'member', expander: 'user' },
  communityMemberTopGames: { property: 'member', expander: 'user_topGames' },
  additionalOrganization: {
    property: 'additionalOrganizations',
    expander: 'additionalOrganization'
  },

  // competition
  competition: { property: 'competition', expander: 'competition' },
  competitionBracket: { property: 'bracket', expander: 'competitionBracket' },
  competitionBracketSettings: {
    property: 'settings',
    expander: 'competitionBracketSettings'
  },
  competitionCommunity: { property: 'representing', expander: 'community' },
  competitionEntry: { property: 'entry', expander: 'competitionEntry' },
  competitionEvent: { property: 'event', expander: 'competitionEvent' },
  competitionLeader: { property: 'leader', expander: 'user' },
  competitionOfficer: { property: 'officer', expander: 'user' },
  communityMemberPreferences: {
    property: 'preferences',
    expander: 'communityMemberPreferences'
  },
  competitionParticipant: {
    property: 'participant',
    expander: 'user'
  },
  competitionProfile: { property: 'profile', expander: 'competitionProfile' },
  competitionSection: { property: 'section', expander: 'competitionSection' },
  competitionSettings: {
    property: 'settings',
    expander: 'competitionSettings'
  },
  supportingCause: {
    property: 'supportingCause',
    expander: 'cause'
  },
  competitionSubmissionScore: {
    property: 'submissionScoreA',
    expander: 'competitionSubmissionScore'
  },
  competitionSubmissionScoreBonus: {
    property: 'bonuses',
    expander: 'competitionSubmissionScoreBonus'
  },
  competitionChannel: {
    property: 'channel',
    expander: 'competitionChannel'
  },
  competitionRepresenting: {
    property: 'representing',
    expander: 'community'
  },

  // domain
  domain: { property: 'domain', expander: 'domain' },

  // entry-request
  entryRequestAgent: { property: 'agent', expander: 'user' },

  //League
  league: { property: 'league', expander: 'league' },

  // organization
  organizationProfile: {
    property: 'profile',
    expander: 'organizationProfiles'
  },
  organizationSettings: {
    property: 'settings',
    expander: 'organizationSettings'
  },

  // payment
  offer: { property: 'offer', expander: 'offer' },
  order: { property: 'order', expander: 'order' },
  product: { property: 'product', expander: 'product' },

  // discord
  discordGuild: { property: 'guild', expander: 'discordGuild' },

  // form
  curatedForm: { property: 'form', expander: 'curatedForm' },
  competitionFormFilter: {
    property: 'filters',
    expander: 'competitionFormFilter'
  },

  // misc
  accessGroup: { property: 'accessGroup', expander: 'accessGroup' }
}

/**
 * Takes an API response object that implements the Expandable pattern
 * and enables developer to resolve nested objects.
 *
 * @example
 *    expand<User>(state, state._expanded, ExpansionType.User)
 *
 * @template T
 * @param {Record<string, any>} state
 * @param {any} expanded
 * @param {...string[]} expansions
 * @returns {(T | undefined)}
 */
export const expand = <T extends Record<string, any>>(
  state: Record<string, any> = {},
  expanded: any = {},
  ...expansions: (keyof typeof Expansions)[]
): T | undefined => {
  return expansions.reduce((result: any, exType: keyof typeof Expansions) => {
    const ex = Expansions[exType]
    const id = result ? result[ex.property]?.id : state?.[ex.property]?.id
    const match = expanded?.[ex.expander]?.find(
      (x: { id: number }) => x.id === id
    )
    return match
  }, null) as T
}

export const expander = <T extends Record<string, any>>(
  state: Record<string, any> = {},
  ...expansions: (keyof typeof Expansions)[]
): T | undefined => expand(state, state?._expanded, ...expansions)

export const expandById = <T extends Record<string, any>>(
  id: number | undefined,
  expanded: any = {},
  expansion: keyof typeof Expansions
): T | undefined => {
  const match = expanded?.[expansion]?.find((x: { id: number }) => x.id === id)
  return match as T
}
