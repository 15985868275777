'use client'

import { StringMap, TOptions } from 'i18next'
import { first, initial, last, set, unset } from 'lodash-es'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { getOverrideKey, TranslationNamespace } from '@/core/translation'

/*
 * Wrapper for organization override locale strings
 */
export const useRcTranslation = (ns?: TranslationNamespace[]) => {
  const { attributes, updateCfg, featStringFinder } = useFeatures()

  const { t: tInternal, i18n } = useTranslation([
    TranslationNamespace.Default,
    ...(ns || [])
  ])

  const t = featStringFinder ? () => '' : tInternal

  const tExternal = (
    i18nKey: string,
    options?: string | TOptions<StringMap>
  ) => {
    // First check for an override of the key, then fallback to the default key
    const override = `${TranslationNamespace.Override}:${getOverrideKey(
      i18nKey
    )}`
    return t([override, i18nKey], {
      ...(options as TOptions<StringMap>)
    })
  }

  const listItems = (items: string[] | undefined, mode: 'and' | 'or') => {
    // "foo" (or nothing if no array passed)
    if (!items || items.length <= 1) {
      return first(items)
    }

    const seperator =
      mode === 'or' ? tExternal('shared.or') : tExternal('shared.and')

    // "foo or bar"
    if (items.length === 2) {
      return items.join(` ${seperator} `)
    }

    // "foo, bar, or baz"
    return [...initial(items), `${seperator} ${last(items)}`].join(', ')
  }

  const updateLocaleOverride = async (i18nKey: string, value: string) => {
    const locale = i18n.language.replace('-', '_')
    const locPath = `locale_v2_${locale}`
    const existing = attributes?.find(it => it.path === locPath)

    const overrideKey = getOverrideKey(i18nKey)

    if (existing && existing.id) {
      const updatedValue = existing.value || {}
      if (value) {
        set(updatedValue, overrideKey, value)
      } else {
        unset(updatedValue, overrideKey)
      }
      await updateCfg(locPath, updatedValue)
    } else {
      const updatedValue = {} as Record<string, any>
      set(updatedValue, overrideKey, value)
      await updateCfg(locPath, updatedValue)
    }
  }

  return { t: tExternal, i18n, listItems, updateLocaleOverride }
}
