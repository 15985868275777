export enum ErrorCode {
  Unknown = 'unknown',
  AlreadyExists = 'common.issue.alreadyExists',
  AlreadyMember = 'competition.entry.issue.alreadyMember',
  CheckInRequired = 'competition.checkIn.required',
  FormMissingResponse = 'competition.form.missing-response',
  CommunityMergeWarningDateCreated = 'community.merge.warning.dateCreated',
  ContactAccountConfirmAdditional = 'contact.account.issue.confirmedAdditional.required',
  DroppedEntry = 'competition.entry.issue.dropped',
  DuplicateUserName = 'user.account.create.issue.duplicateName',
  DuplicateAlternativeName = 'competition.entry.alternativeName.issue.duplicate',
  ParticipantAlreadyExists = 'competition.participant.issue.userAlreadyExists',
  Illegal = 'common.issue.state.illegal',
  InAnotherEntry = 'competition.entry.issue.inAnotherEntry',
  IndividualOnly = 'competition.entry.issue.individualOnly',
  Invalid = 'common.issue.invalid',
  MfaRequired = 'common.issue.mfa-required',
  MissingRequiredNetwork = 'competition.entry.issue.missingRequiredNetwork',
  NotFound = 'common.issue.notFound',
  NotInOrgCommunity = 'competition.entry.issue.notInOrgCommunity',
  NotInRepresenting = 'competition.entry.issue.notInRepresenting',
  RepresentSameCommunity = 'competition.entry.issue.representSameCommunity',
  PartyFinderOverlappingRally = 'party-finder.issue.overlapping-rally',
  VerificationEmailAlreadyTaken = 'verification.request.issue.emailAlreadyTaken',
  ContactAccountAlreadyExists = 'contact-account.issue.alreadyExists',
  ContactAccountAlreadyClaimed = 'contact-account.email.issue.alreadyClaimed',
  ContactAccountInvalid = 'contact.account.handle.issue.invalid',
  GrantedPermitAlreadyExists = 'granted-permit.create.issue.alreadyHasPermit',
  TeamFull = 'competition.entry.issue.teamFull',
  TeamSizeMin = 'competition.entry.issue.teamSizeMin',
  PermitCountMin = 'competition.entry.issue.permitCountMin',
  Pattern = 'Pattern',
  Size = 'Size',
  Email = 'Email'
}
