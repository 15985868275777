import { AttributeCreateCommand } from '@rallycry/api-suite-typescript/dist/models/AttributeCreateCommand'
import { AttributeUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/AttributeUpdateCommand'
import { QueryAttributesRequest } from '@rallycry/api-suite-typescript/dist/apis/OrganizationAttributeControllerApi'
import { OrganizationAttributeControllerApi as Ctrl } from '@rallycry/api-suite-typescript/dist/apis/OrganizationAttributeControllerApi'
import {
  OrganizationMetaResource,
  OrganizationResource
} from '@rallycry/api-suite-typescript/dist/models'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useOrganization } from './useOrganization'
import { useController } from '@/core/hooks/useSWRApi'

export const useOrganizationAttributes = (
  options?: EntityOptions<QueryAttributesRequest> & {
    attributes?: any
    organization?: OrganizationResource
    organizationMeta?: OrganizationMetaResource
  }
) => {
  const { read, meta, organization, profile } = useOrganization(options)
  const { ctrl } = useController(Ctrl)

  const entity = useQueryEntity({
    key: 'useOrganizationAttributes',
    persist: true,
    ...options,
    metas: [meta],
    request: { organizationId: organization?.id, ...options?.request },
    read: (req, { attributes }) =>
      ctrl({ metas: { attributes } }).queryAttributes(req),
    create: (cmd: AttributeCreateCommand) =>
      ctrl().createOrganizationAttribute({
        organizationId: organization?.id!,
        AttributeCreateCommand: cmd
      })
  })

  const update = async (id: number, cmd: AttributeUpdateCommand) => {
    // optimistically update data
    entity.query.mutate(data => {
      if (!data) return
      for (const page of data) {
        for (const entry of page.content!) {
          if (entry.id === id) {
            entry.value = cmd.value
          }
        }
      }
      //deep clone data
      return JSON.parse(JSON.stringify(data))
    }, false)

    await ctrl()
      .updateOrganizationAttribute({
        id,
        AttributeUpdateCommand: cmd
      })
      .then(() => meta.mutate())
  }

  const remove = async (id: number) => {
    // optimistically update data
    await entity.query.mutate(data => {
      if (!data) return
      for (const page of data) {
        page.content = page.content?.filter(it => it.id !== id) || []
      }
      return [...data]
    }, false)

    await ctrl().removeOrganizationAttribute({ id })
    await meta.mutate()
  }

  return { ...entity, attributes: entity.flat, profile, update, remove }
}
