import { useRef, useState } from 'react'

export const useAcceptable = () => {
  const [_, setTouched] = useState(0)
  const acceptedRef = useRef<{ value: string }>({ value: '' })

  const appendToAccepted = (value: string) => {
    acceptedRef.current.value =
      acceptedRef.current.value && !acceptedRef.current.value.includes(value)
        ? `${acceptedRef.current.value},${value}`
        : value
    setTouched(t => t + 1)
  }

  const resetAccepted = () => {
    if (!acceptedRef.current.value) return
    acceptedRef.current.value = ''
    setTouched(t => t + 1)
  }

  const getAccepted = () => {
    return acceptedRef.current.value
  }

  return {
    accepted: getAccepted,
    appendToAccepted,
    resetAccepted
  }
}
