'use client'

import { OrganizationResource } from '@rallycry/api-suite-typescript/dist/models/OrganizationResource'
import { isString } from 'lodash-es'
import { useCallback } from 'react'
import { useParams, usePathname, useRouter } from 'next/navigation'
import { useConfig } from '../../entity/site/useConfig'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'
import { useImpersonation } from '@/components/providers/site/ImpersonationProvider'
import {
  AdministrationSubRoute,
  CommunityRoute,
  CompetitionRoute,
  DiscoveryRoute,
  PartyFinderRoute,
  RootRoute
} from '@/core/route-keys'
import { OAuthErrorRoute } from '@/components/pages/Site/OAuthError'

type SubRoutes =
  | AdministrationSubRoute
  | DiscoveryRoute
  | CommunityRoute
  | CompetitionRoute
  | PartyFinderRoute
  | OAuthErrorRoute
  | string

export interface NavigationParams {
  root?: RootRoute
  rootId?: number | string
  subRoute?: SubRoutes
  subId?: number | string
  query?: string
  organization?: string
  rootMode?: boolean
  to?: string
}

export const getPathInternal = (
  orgKey: string | undefined,
  locale: string,
  params: NavigationParams
) => {
  if (params?.to) return params?.to
  let result = ''
  if (locale && locale !== 'en') result += `/${locale}`
  if (orgKey) result += `/${orgKey}`
  result += `/${params?.root}`
  if (params?.rootId) result += `/${params?.rootId}`
  if (params?.subRoute) result += `/${params?.subRoute}`
  if (params?.subId) result += `/${params?.subId}`
  if (params?.query) result += `?${params?.query}`
  return result
}

/*
 * Standaridized route creation utilities that are organization and hosting-mode aware.
 * Should be used for all intra-site navigation and links to ensure accuracy and consistency.
 */
export const useNavigation = () => {
  const config = useConfig()
  const { stopImpersonation } = useImpersonation()
  const { organizationKey, locale } = useParams()
  const orgKey = organizationKey as string
  const loc = locale as string
  const { signOut: signOutFirebase } = useFirebase()
  const pathname = usePathname()
  const router = useRouter()

  /*
   * Get absolute path e.g. /root/rootId/subRoute/subId?query
   */
  const getPath = (params: NavigationParams | string) =>
    isString(params)
      ? params
      : getPathInternal(
          config.rootMode || params?.rootMode
            ? undefined
            : params?.organization || orgKey,
          loc,
          params
        )

  /*
   * Get full URL link e.g. https://rallycry.gg/org/root/rootId/subRoute/subId?query
   */
  const getLinkUrl = (params: NavigationParams) =>
    `${window.location.protocol}//${window.location.host}${getPath(params)}`

  /*
   * Navigate directly to a view, with history.
   */
  const navTo = (params: string | NavigationParams, skipScroll?: boolean) => {
    const path = typeof params === 'string' ? params : getPath(params)
    const isSamePath = pathname === path
    isSamePath
      ? router.replace(path, {
          scroll: !skipScroll
        })
      : router.push(path, {
          scroll: !skipScroll
        })
  }

  /*
   * Navigate directly to a view, replacing current page in history.
   */
  const replaceTo = (
    params: string | NavigationParams,
    skipScroll?: boolean
  ) => {
    router.replace(typeof params === 'string' ? params : getPath(params), {
      scroll: !skipScroll
    })
  }

  const back = () => router.back()

  const changeOrg = (org?: OrganizationResource | null) => {
    if (!org) return
    if (org.rootForDomain) {
      window.location.href = `https://${org.rootForDomain}`
    } else {
      router.push(getPath({ organization: org.key, root: RootRoute.Root }))
    }
  }

  const notFound = () => replaceTo({ root: RootRoute.NotFound })

  const signOut = async () => {
    await signOutFirebase()
    await stopImpersonation()
    navTo({ root: RootRoute.Login })
  }

  return {
    path: pathname,
    getPath,
    getLinkUrl,
    navTo,
    replaceTo,
    back,
    changeOrg,
    notFound,
    signOut
  }
}
