/*
 * Keys used for i18n translation files.
 */
export enum TranslationNamespace {
  Default = 'translation',
  Override = 'override',
  PartyFinder = 'party-finder',
  Administration = 'administration',
  Auth = 'auth',
  Causes = 'causes',
  Community = 'community',
  Competition = 'competition',
  CommunityType = 'community-type',
  Contact = 'contact',
  DepartmentType = 'department-type',
  Discovery = 'discovery',
  Home = 'home',
  InboxMessage = 'inbox-message',
  Marketing = 'marketing',
  MarketingPikapp = 'marketing-pikapp',
  Payment = 'payment',
  Permit = 'permit',
  Profile = 'profile',
  Registration = 'registration',
  SchoolGeneric = 'school-generic',
  SchoolFraternity = 'school-fraternity',
  YouthGeneric = 'youth-generic',
  YouthGenericV2 = 'youth-generic-v2',
  AfgSpeciality = 'speciality',
  Military = 'military'
}

// "default" keys do not include a namespace: prefix so sometimes its nice to add it before operating on keys generically
export const getNamespacedKey = (i18nKey?: string) =>
  i18nKey?.includes(':') ? i18nKey : `translation:${i18nKey}`

// "override" keys are all stored as a single object, with namespace as the top level property.
// tip: When accessing these keys, this should value should be prefixed e.g. `${TranslationNamespace.Override}:keyNamespace.your.key.path`
export const getOverrideKey = (i18nKey?: string) =>
  getNamespacedKey(i18nKey).replace(':', '.')
