'use client'

import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { useParams } from 'next/navigation'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import Stack from '@mui/material/Stack'
import { useLocalStorage } from '@/core/hooks/useLocalStorage'
import { BaseProviderState, Initial } from '@/core/base-provider-state'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { useAcceptable } from '@/core/hooks/useAcceptable'

/**
 * Enables setting data within a page context.
 */
export const usePage = () => useContext(PageContext)

interface PageContext extends BaseProviderState {
  backgroundImage?: string | null

  //snackbar props
  isOpen?: boolean
  action?: React.ReactNode
  onDismissed?: () => any
  message?: React.ReactNode
  duration?: number
}

const isDevelopment = process.env.NODE_ENV !== 'production'
const RedirectKey = 'loginRedirect'

const usePageApi = () => {
  const acceptable = useAcceptable()
  const confettiContainer = useRef<any>(null)
  const { organizationKey } = useParams()
  const [state, setState] = useState<PageContext>(Initial)
  const [contact, setContact] = useState<{
    title: React.ReactNode
    subtitle: React.ReactNode
    action: (description: string) => Promise<{ chatId?: string }>
    entity?: { name?: string }
    existing: (
      ticketId: number,
      description: string,
      onResolved: (chatId?: string) => void
    ) => React.ReactNode
    discord?: string
  }>()
  const [redirect, setRedirect] = useLocalStorage({
    key: RedirectKey + organizationKey,
    defaultValue: ''
  })

  const open = (
    action: React.ReactNode,
    message: React.ReactNode,
    duration?: number,
    onDismissed?: () => any
  ) =>
    setState(s => ({
      ...s,
      isOpen: true,
      action,
      message,
      duration,
      onDismissed
    }))

  const close = () => {
    setState({ isOpen: false })
  }

  const [showKeys, setShowKeys] = useState(false)
  useEffect(() => {
    const handle = (e: KeyboardEvent) => {
      setShowKeys(e.ctrlKey && e.altKey)
    }
    document.addEventListener('keydown', handle, false)
    document.addEventListener('keyup', handle, false)

    return () => {
      document.removeEventListener('keydown', handle, false)
      document.removeEventListener('keyup', handle, false)
    }
  }, [])

  return {
    ...state,
    ...acceptable,
    confettiContainer,
    redirect,
    setRedirect,
    contact,
    setContact,
    open,
    close,
    showKeys,
    isDevelopment
  }
}

// Exported for Storybook Mocking only
export type PageType = ReturnType<typeof usePageApi>
export const PageContext = createContext(Initial as PageType)
export const PageProvider = ({
  children,
  ...props
}: {
  children?: React.ReactNode
}) => {
  const value = usePageApi()
  return (
    <PageContext.Provider value={value} {...props}>
      {children}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        ClickAwayListenerProps={{
          onClickAway: () => {
            // noop
          }
        }}
        open={value.isOpen}
        onClose={value.close}
        autoHideDuration={value.duration}
      >
        <Alert
          variant='standard'
          severity='info'
          action={
            <Stack direction='row' spacing={1} alignItems={'center'}>
              {value.action}
              <RcIconButton
                aria-label='close'
                color='inherit'
                onClick={() => {
                  value.onDismissed?.()
                  return value.close()
                }}
                icon={['fal', 'times']}
              />
            </Stack>
          }
        >
          {value.message}
        </Alert>
      </Snackbar>
    </PageContext.Provider>
  )
}
